import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import { useCookies } from 'react-cookie';
import { Pie } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import app from './firebaseConfig';
import './Feedback.css';

const Feedback = () => {
    const [cookies] = useCookies(['uid', 'gamePin']);
    const [feedbackData, setFeedbackData] = useState(null);
    const [userPoints, setUserPoints] = useState(0);
    const [globalAnswers, setGlobalAnswers] = useState([]);
    const [loading, setLoading] = useState(true);

    const userId = cookies.uid;
    const gameId = cookies.gamePin;
    const db = getDatabase(app);

    const feedbackNumberRef = ref(db, `/codes/${gameId}/feedbackNumber`);

    useEffect(() => {
        if (!userId || !gameId) {
            console.error('Missing user ID or game ID from cookies');
            setLoading(false);
            return;
        }

        const unsubscribeFeedbackNumber = onValue(feedbackNumberRef, (snapshot) => {
            const feedbackNumber = snapshot.val();
            if (feedbackNumber) {
                fetchFeedbackData(feedbackNumber);
            } else {
                console.error('No feedback number found');
                setLoading(false);
            }
        });

        return () => {
            unsubscribeFeedbackNumber();
        };
    }, [userId, gameId]);

    const fetchFeedbackData = (feedbackNumber) => {
        const feedbackRef = ref(db, `/codes/${gameId}/players/${userId}/answers/${feedbackNumber}`);
        const userScoreRef = ref(db, `/codes/${gameId}/players/${userId}/score`);
        const questionRef = ref(db, `/codes/${gameId}/questions/${feedbackNumber}`);
        const globalAnswersRef = ref(db, `/codes/${gameId}/globalAnswers/${feedbackNumber}`);

        const unsubscribeFeedback = onValue(feedbackRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setFeedbackData(data);
            } else {
                console.log('No feedback data found');
            }
        });

        const unsubscribeScore = onValue(userScoreRef, (snapshot) => {
            const score = snapshot.val();
            if (score !== null) {
                setUserPoints(score);
            }
        });

        const unsubscribeQuestion = onValue(questionRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setFeedbackData(prevState => ({ ...prevState, ...data }));
            }
            setLoading(false);
        });

        const unsubscribeGlobalAnswers = onValue(globalAnswersRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const answers = Object.entries(data).map(([playerId, answerData]) => ({
                    playerId,
                    ...answerData,
                }));
                setGlobalAnswers(answers);
            }
        });

        return () => {
            unsubscribeFeedback();
            unsubscribeScore();
            unsubscribeQuestion();
            unsubscribeGlobalAnswers();
        };
    };

    if (loading) {
        return <p>Loading feedback...</p>;
    }

    if (!feedbackData) {
        return <p>No feedback data found.</p>;
    }

    const { answer, correct, correctAnswer } = feedbackData;

    const answerCounts = globalAnswers.reduce((acc, { answer }) => {
        acc[answer] = (acc[answer] || 0) + 1;
        return acc;
    }, {});

    const chartData = {
        labels: Object.keys(answerCounts),
        datasets: [
            {
                data: Object.values(answerCounts),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
        },
    };

    return (
        <div className="feedback-container d-flex flex-column justify-content-center align-items-center min-vh-100">
            <div className="feedback-content card border-0 shadow p-4">
                <h2>Feedback</h2>
                <p>Your answer: {answer} ({correct ? 'Correct' : 'Incorrect'})</p>
                <p>Correct answer: {correctAnswer}</p>
                <p>Your current points: {userPoints}</p>
                <div className="chart-container" style={{ width: '80%', height: '300px' }}>
                    <Pie data={chartData} options={chartOptions} />
                </div>
            </div>
        </div>
    );
};

export default Feedback;
