import React, { useState } from 'react';
import { getAuth, signInAnonymously } from 'firebase/auth';
import firebaseConfig from "./firebaseConfig";
import app from "./firebaseConfig";
import styled from "@emotion/styled";

const UserInput = () => {

    const [name, setName] = useState('');
    const auth = getAuth();

    const createUserInDatabase = (uid, username, gameId) => {
        // fetch URL
        // https://de1.sightbot.xyz:25483/addUserToRealtimeDB?username=username&uid=uid&gameId=gameId
        fetch(`https://de1.sightbot.xyz:25483/addUserToRealtimeDB?username=${username}&uid=${uid}&gameId=${gameId}`).then(r => r.json()).then(data => {
            console.log(data);
            if(data===1){
                // redirect to game page
                window.location.href = '/game';
                return true;

            } else{
                return false;
            }
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const userCredential = await signInAnonymously(auth);
            const uid = userCredential.user.uid;
            // save in cookies as uid
            document.cookie = `uid=${uid}`;
    // fetch from cookies
    const gameId = document.cookie.split(';').find(cookie => cookie.includes('gamePin')).split('=')[1];

createUserInDatabase(uid, name, gameId);

            setName(''); // Clear the input field
        } catch (error) {
            console.error('Error creating anonymous user:', error);
        }
    };

    return (
        <div>
            <b className={"text-white"}>SIGMA</b>
        <form onSubmit={handleSubmit}>
            <label htmlFor="name" className={"text-white"}>Enter your name:</label>
            <input
                type="text"
                id="name"
                className={"form-control bg-dark text-white"}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
            />


            <button className={"form-control btn-success"} type="submit">Submit</button>
        </form>
        </div>
    );
};

export default UserInput;
