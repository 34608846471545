import React, { useEffect, useState } from 'react';
import './Lobby.css'; // Import your CSS stylesheet for styling
import { getDatabase, onValue, ref } from "firebase/database"; // Firebase imports

import app from "./firebaseConfig"; // Import Firebase app instance

// Optional: Import useAuth hook if needed for authentication checks
// import 'firebase/auth';
// import { useAuth } from 'firebase/auth';

import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection

const Lobby = ({ gameId }) => {
    const navigate = useNavigate(); // Function for navigation

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [players, setPlayers] = useState([]);
    const [gameStatus, setGameStatus] = useState(null);

    // Authentication check (if using Firebase Auth)
    // useEffect(() => {
    //   const auth = useAuth(); // Get user object from Firebase Auth
    //
    //   const unsubscribe = auth.onAuthStateChanged((user) => {
    //     setIsAuthenticated(!!user); // Set state based on user object existence
    //   });
    //
    //   return unsubscribe; // Cleanup function to prevent memory leaks
    // }, []); // Empty dependency array for initial authentication check

    // Handle redirection on unauthenticated state (if using Firebase Auth)
    // if (!isAuthenticated) {
    //   navigate('/'); // Redirect to main screen on unauthenticated state
    //   return null; // Prevent rendering unnecessary content
    // }

    // Fetch player data
    useEffect(() => {
        // ... existing code for timeout and gameId retrieval (if needed)

        if (!gameId) {
            return; // No gameId found, exit
        }

        const db = getDatabase(app);
        const playersRef = ref(db, `/codes/${gameId}/players`);

        const unsubscribe = onValue(playersRef, (snapshot) => {
            const playerData = snapshot.val(); // Object containing player information

            // Process player data here (update state or pass to Lobby component)
            const players = Object.values(playerData); // Convert object to array of players
            setPlayers(players); // Update state with player data
        }, (error) => {
            console.error('Error fetching player data:', error); // Log errors
        });

        // Cleanup function to unsubscribe from listener on unmount
        return () => unsubscribe();
    }, [gameId]); // Dependency array ensures effect runs only when gameId changes

    // Set game status (replace with actual logic)
    useEffect(() => {
        setGameStatus('waiting'); // Replace with actual status fetching logic
    }, []); // Empty dependency array ensures effect runs only once on component mount

    return (
        <div className="lobby-container d-flex flex-column justify-content-center align-items-center min-vh-100">
            <div className="lobby-background bg-cover" style={{ backgroundImage: `url('https://cdn.kincode.net/ludoplex.svg')` }}></div>

            <header className="lobby-header text-center mb-5">
                <h1 className="lobby-title display-1 text-white">Computer Quiz</h1>
                <p className="lobby-description text-white">10/10 Quality</p>
            </header>

            <div className="player-list card border-0 shadow p-4">
                <h2>Players</h2>
                <ul className="list-group list-group-flush">
                    {players.map((player) => (
                        <li key={player.id} className="list-group-item">
                            <span>{player.username}</span>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="game-status card border-0 shadow p-4 mt-5">
                <p>Waiting for players to join.</p>
            </div>
        </div>
    );
};

export default Lobby;
