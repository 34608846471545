import React, { useEffect, useState } from 'react';
import { getDatabase, onValue, ref } from "firebase/database"; // Firebase imports
import app from "./firebaseConfig"; // Import Firebase app instance
import Cookies from 'js-cookie'; // Import js-cookie for accessing cookies
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';
import "./questionDisplay.css";
// import firebase
import { getAuth, signInAnonymously } from 'firebase/auth';

function submitAnswer(answer) {

    // get gamePin from cookie, uid from firebase and answer from form
    const gamePin = Cookies.get('gamePin');
    const auth = getAuth();
    const uid = Cookies.get('uid');
    console.debug(gamePin, uid, answer);
    // fetch URL
    // https://de1.sightbot.xyz:25483/submitAnswer?gameId=gamePin&uid=uid&answer=answer&questionId=gameState
    // gamestate = question id
    // fetch from realtime db
    const db = getDatabase(app);
    const gameStateRef = ref(db, `/codes/${gamePin}/status`);
    // only need it once
    onValue(gameStateRef, (snapshot) => {
        const gameState = snapshot.val();
        console.log('Game state retrieved:', gameState);
        try {
            fetch(`https://de1.sightbot.xyz:25483/submitAnswer?gameId=${gamePin}&uid=${uid}&answer=${answer}&questionId=${gameState}`).then(r => r.json()).then(data => {
                console.log(data);
                // clear answer
            });
        } catch (error) {
            console.error('Error submitting answer:', error);

        }
    });



}

const QuestionDisplay = () => {
    const [gamePin, setGamePin] = useState(null);
    const [gameState, setGameState] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [answer, setAnswer] = useState('');

    // Fetch gamePin from cookie
    useEffect(() => {
        const storedPin = Cookies.get('gamePin');
        console.log('Stored game pin:', storedPin);
        setGamePin(storedPin);
    }, []);

    // Fetch gameState from database
    useEffect(() => {
        if (!gamePin) return;

        const db = getDatabase(app);
        const gameStateRef = ref(db, `/codes/${gamePin}/status`);
        const unsubscribeGameState = onValue(gameStateRef, (snapshot) => {
            const stateData = snapshot.val();
            console.log('Game state retrieved:', stateData);
            setGameState(stateData);
        }, (error) => {
            console.error('Error fetching game state:', error);
        });

        return () => unsubscribeGameState();
    }, [gamePin]);

    // Fetch question based on gamePin and gameState
    useEffect(() => {
        if (!gamePin || !gameState) return;

        const db = getDatabase(app);
        const questionRef = ref(db, `/codes/${gamePin}/questions/${gameState}`);

        const unsubscribeQuestion = onValue(questionRef, (snapshot) => {
            const questionData = snapshot.val();
            setCurrentQuestion(questionData);
        }, (error) => {
            console.error('Error fetching current question:', error);
        });

        return () => unsubscribeQuestion();
    }, [gamePin, gameState]);

    // Render question if available
    const renderQuestion = () => {
        if (!currentQuestion) {
            return null; // No question available yet
        }
        const {questionTitle, questionText, image, inputType, options} = currentQuestion;

        // Display question title conditionally
        const displayTitle = questionTitle ? (
            <h2 className={"text-white"}>{questionTitle}</h2>
        ) : null;


        // Display question image conditionally
        const displayImage = image ? (
            <img height={"50%"} width={"50%"} src={image} alt="Question image" className="align-content-center align-content-lg-center question-image mb-3 rounded-5 border-10"/>
        ) : null;
        // Display question content based on input type
        let answerChoices;
        switch (inputType) {
            case 'text':
                answerChoices = (
                    <textarea
                        className="form-control"
                        color={"#ffffff"}
                        placeholder={"Answer"}
                        rows="1"
                        maxLength={30}
                        aria-multiline={"false"}
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                    />
                );
                break;
            case 'MCQ':

                answerChoices = (
                    <ul className="list-group list-group-flush">
                        {options.map((choice, index) => (
                            <li key={index} className="list-group-item ">
                                <input
                                    className={"form-check-input"}
                                    type="radio"
                                    name="answer"
                                    id={`choice-${index}`}
                                    value={choice}
                                    checked={answer === choice} // Set checked based on state
                                    onChange={(e) => setAnswer(e.target.value)} // Update state on change
                                />
                                <label className={"form-check-label"} htmlFor={`choice-${index}`}>{choice}</label>
                            </li>
                        ))}
                    </ul>
                );

                // Update submitAnswer function to use selectedChoice (if using Option 1)
                // submitAnswer(selectedChoice) // Modify if using Option 1

                break;

            default:
                answerChoices = <p>Unsupported question type: {inputType}</p>;
        }

        return (
            <div className="question-container border-0 shadow p-4 mt-5 align-content-center flex-fill">
                {displayTitle}
                {displayImage}
                <p className={"text-white"}>{questionText}</p>
                <div className={"form-floating"}>
                    {answerChoices}
                </div>
                {/*    submit button*/}
                <button onClick={async () => {
                  try {
                      if(!answer) {
                          return;
                      }
                      await submitAnswer(answer);
                      setAnswer('');

                        // add the alert to the page
                        const alert = document.createElement('div');
                        alert.className = 'alert alert-success alert-dismissible fade show mt-3';
                        alert.role = 'alert';
                        alert.innerHTML = `
                            Success! Your answer has been submitted.\nYou can change your answer until the question is locked.
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        `;
                        document.body.appendChild(alert);
                        // remove the alert after 5 seconds
                        setTimeout(() => {
                            alert.remove();
                        }, 5000);


                  }
                    catch (error) {
                        console.error('Error submitting answer:', error);
                        // add the alert to the page
                        const alert = document.createElement('div');
                        alert.className = 'alert alert-danger alert-dismissible fade show mt-3';
                        alert.role = 'alert';
                        alert.innerHTML = `
                            Error submitting answer. Please try again.
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        `;
                        document.body.appendChild(alert);
                        // remove the alert after 5 seconds
                        setTimeout(() => {
                            alert.remove();
                        }, 5000);
                    }
                }}
                     form={""} className="btn btn-success text-white mt-3 ">
                    <div className={"align-content-lg-center p-2 justify-content-between "}>
                        Submit{" "}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5"/>
                        </svg>
                    </div>
                </button>
            </div>
        );
    }

    return (
        <div>
            {renderQuestion()}
        </div>
    );
}

export default QuestionDisplay;
