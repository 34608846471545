// firebaseConfig.js (or similar)
import { initializeApp } from "firebase/app";

const firebaseConfig = {

    apiKey: "AIzaSyCN-U3ZsF_mIcxC8oDfo1nQsgyyU0MSYp8",

    authDomain: "quiz-63eec.firebaseapp.com",

    databaseURL: "https://quiz-63eec-default-rtdb.europe-west1.firebasedatabase.app",

    projectId: "quiz-63eec",

    storageBucket: "quiz-63eec.appspot.com",

    messagingSenderId: "732925046829",

    appId: "1:732925046829:web:faea8f67cdb95fff2348a4",

    measurementId: "G-L6X1MLLSJE"

};



const app = initializeApp(firebaseConfig);

export default app;
