import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import NotFound from "./NotFound";
import CreateUserInDatabase from "./createUserInDatabase";
import UserInput from "./userInput";
import GameMan from "./gameMan";
import GameManager from "./gameMan"; // Import the App component

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter> {/* Wrap the entire app with BrowserRouter */}
            <Routes>
                <Route path="/" element={<App />} /> {/* Renders App component for / */}
                <Route path="/createUser" element={<UserInput  /> } />
                <Route path="/game" element={<GameManager  /> } />
                <Route path="*" element={<NotFound  /> } />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);


