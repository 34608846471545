import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { ThemeContext } from './themeContext';
import axios from "axios"; // Import the theme context
const theme = 'dark';
const StyledInput = styled.input(({ theme }) => ({
        backgroundColor: theme === 'dark' ? '#222' : '#fff', // In case the context ever changes
        border: `1px solid ${theme === 'dark' ? '#444' : '#ddd'}`,
        borderRadius: "5px",
        fontSize: "24px",
        color: theme === 'dark' ? '#fff' : '#333',
}));
class FourDigitCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: '',
        };
    }

    handleChange = (event) => {
        // Limit input to 4 digits
        const newCode = event.target.value.slice(0, 4);
        this.setState({ code: newCode });
    }
    setError = (error) => {
        const errorElements = document.getElementsByClassName("error");

// Loop through elements and set their inner text/content
        for (let i = 0; i < errorElements.length; i++) {
            errorElements[i].textContent = "Invalid code";
            errorElements[i].style.color = "red";
        }

    }
    handleSubmit = (event) => {
        event.preventDefault();
        // if (this.state.code.length !== 4)  or not a number
        if (this.state.code.length !== 4 || isNaN(this.state.code)) {
this.setError();

            return;
        }
        // fetch
        //http://localhost:8080/validate/code
        axios
            .get(`https://de1.sightbot.xyz:25483/validate/${this.state.code}`, {
            })
            .then((response) => {
                console.log(response);
                if (response.data === 1) {
                    // save gamepin to cookies
                    document.cookie = `gamePin=${this.state.code}`;
                    window.location.href = '/createUser';
                } else {
                    this.setError();

            }})
            .catch((error) => {
                console.log(error);
    })
    }


    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                {/*padding*/}
                <br></br>

                <StyledInput type="text" id="code" maxLength={4} value={this.state.code} onChange={this.handleChange} theme={theme} />
                <button class="btn btn-outline-success" type="submit">Join Game</button>
            </form>
        );
    }
}


export default FourDigitCode;
