import React from 'react';
import './App.css'; // Import your custom CSS (optional)
import FourDigitCode from './fourDigitCode'; // Import your component
import { Container, Row, Col } from 'react-bootstrap';
import { ThemeContext, ThemeProvider } from './themeContext'; // Import context
const theme = 'dark'; // Set default theme
function App() {
    return (
        <ThemeProvider> {/* Wrap entire app in ThemeProvider */}
            <Container fluid className={`app vh-100 dark`}> {/* Set background color and class for dark theme */}
                <Row className="justify-content-center">
                    <Col md={6} className="container">
                        <h1 className="title">Enter Your Code</h1>
                        <FourDigitCode />
                    {/*    error text placeholder*/}
                        <h1 className="error tada-loop my-custom-tada" ></h1>
                    </Col>
                </Row>
            </Container>
        </ThemeProvider>
    );
}

export default App;
