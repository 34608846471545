import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { getDatabase, ref, onValue } from 'firebase/database';
import {Bar, Bubble, Doughnut, Line, PolarArea, Radar} from 'react-chartjs-2';
import Chart, {BarElement} from 'chart.js/auto';
import app from './firebaseConfig';
import './Finished.css';

const Finished = () => {
    const [cookies] = useCookies(['gamePin', 'userId']);
    const [globalScores, setGlobalScores] = useState({});
    const [usernames, setUsernames] = useState({});
    const [loading, setLoading] = useState(true);

    const gameId = cookies.gamePin;
    const userId = cookies.userId;
    const db = getDatabase(app);

    useEffect(() => {
        if (!gameId) {
            console.error('Missing game ID from cookies');
            setLoading(false);
            return;
        }

        const globalScoresRef = ref(db, `/codes/${gameId}/globalScore`);
        const playersRef = ref(db, `/codes/${gameId}/players`);

        const unsubscribeGlobalScores = onValue(globalScoresRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setGlobalScores(data);
            } else {
                console.error('No global scores found');
            }
            setLoading(false);
        });

        const unsubscribeUsernames = onValue(playersRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const usernames = {};
                for (const [playerId, playerInfo] of Object.entries(data)) {
                    usernames[playerId] = playerInfo.username;
                }
                setUsernames(usernames);
            } else {
                console.error('No player data found');
            }
        });

        return () => {
            unsubscribeGlobalScores();
            unsubscribeUsernames();
        };
    }, [gameId]);

    if (loading) {
        return <p>Loading leaderboard...</p>;
    }

    if (Object.keys(globalScores).length === 0) {
        return <p>No scores available.</p>;
    }

    const sortedScores = Object.entries(globalScores).sort(([, a], [, b]) => b.score - a.score);

    const chartData = {
        labels: sortedScores.map(([playerId]) => usernames[playerId] || playerId),
        datasets: [
            {
                label: 'Scores',
                data: sortedScores.map(([, { score }]) => score),
                backgroundColor: '#36A2EB',
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        animations: {
            tension: {
                duration: 1000,
                easing: 'linear',
                from: 1,
                to: 0,
                loop: true
            }
        },
        maintainAspectRatio: false,
        colors: ['#36A2EB'],
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <div className="finished-container d-flex flex-column justify-content-center align-items-center min-vh-100">
            <div className="finished-content card border-0 shadow p-4">
                <h2>Leaderboard</h2>
                <h4>Thank you for playing.</h4>
                <table className="table">
                    <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Player</th>
                        <th>Score</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sortedScores.map(([playerId, {score}], index) => (
                        <tr key={playerId} className={playerId === userId ? 'current-user' : ''}>
                            <td>{index + 1}</td>
                            <td>{usernames[playerId] || playerId}</td>
                            <td>{score}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className="chart-container" style={{width: '80%', height: '400px'}}>
                    <Bar data={chartData} options={chartOptions}/>

                </div>
            </div>
        </div>
    );
};

export default Finished;
