import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
// import firebase app
import app from "./firebaseConfig";
import Lobby from "./Lobby";
import QuestionDisplay from "./questionDisplay";
import Feedback from "./Feedback";
import Finished from "./Finished";

const GameManager = number => {
    const [gameStatus, setGameStatus] = useState(null);
    const [gameId, setGameId] = useState(null);

    useEffect(() => {


        // Function to retrieve gameId from cookie
        const getGameIdFromCookie = () => {
            const cookieValue = document.cookie.split(';').find(cookie => cookie.includes('gamePin')).split('=')[1];
            return cookieValue ? cookieValue : null;
        };

        const gameId = getGameIdFromCookie();
        setGameId(gameId);

        if (!gameId) {
            return; // No gameId found in cookies, exit
        }

        const db = getDatabase(app);
        const gameRef = ref(db, `/codes/${gameId}/status`);

        const unsubscribe = onValue(gameRef, (snapshot) => {
            const data = snapshot.val();
            console.log('Game status retrieved:', data); // Log retrieved data
            setGameStatus(data);
        }, (error) => {
            console.error('Error fetching game status:', error); // Log errors
        });

        // Cleanup function to unsubscribe from listener on unmount
        return () => {

            unsubscribe();
        };
    }, []); // Empty dependency array to run only once on component mount

    if (gameStatus === null) {
        return <p>Loading game status...</p>;
    } else if (!gameId) {
        return <p>Game ID not found in cookies.</p>;
    } else if (gameStatus === 'error') {
        return <p>Error loading game status.</p>;
    }




    switch (gameStatus) {
        case "waiting":
            return <Lobby gameId={gameId} />;
        case "finished":
            return <Finished/>;
        case "feedback":
            return <Feedback/>
        case "marking":
            return <marquee className={"text-white h1"}>Awaiting marking</marquee>;
        default:
            return <QuestionDisplay/>
    }
};

export default GameManager;
