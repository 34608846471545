import React, { createContext, useState } from 'react';

const ThemeContext = createContext({
    theme: 'dark', // Set default theme to 'dark'
    toggleTheme: () => {},
});

const ThemeProvider = ({ children }) => {
    const theme = 'dark'; // Set default theme to 'dark'
    return (
        <ThemeContext.Provider value={{ theme: 'dark' }}>
            {children}
        </ThemeContext.Provider>
    );
};

export { ThemeContext, ThemeProvider };
