import React from 'react';
import "./App.css";
const NotFound = () => {
    return (
        <div>
            <h1>404 - Page Not Found</h1>
            <p>The page you requested could not be found.</p>
        </div>

    );
};

export default NotFound;
